import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import FormField from "components/FormField";
import { useState, useEffect, useMemo } from "react";
import { serverBaseURL } from "common/settings";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function SiteManager() {
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [currentEditSiteId, setCurrentEditSiteId] = useState(0);
  const [siteName, setSiteName] = useState("");
  const [country, setCountry] = useState("");
  const [coordinate, setCoordinate] = useState("");
  const [installationDateNew, setInstallationDateNew] = useState("");
  const [installationDateUsed, setInstallationDateUsed] = useState("");
  const [panelNumber, setPanelNumber] = useState(0);
  const [outputPerPanel, setOutputPerPanel] = useState(0);
  const [panelOutput, setPanelOutput] = useState("");
  const [timezone, setTimezone] = useState("Etc/GMT+7");
  const [inputTimezone, setInputTimezone] = useState("");
  const [powerDcRated, setPowerDcRated] = useState(1000);
  const [gammaPdc, setGammaPdc] = useState(-0.005);
  const [tilt, setTilt] = useState(40);
  const [azimuth, setAzimuth] = useState(180);
  const [mu, setMu] = useState(0.8);
  const [runningDataFiles, setRunningDataFiles] = useState({
    columns: [
      { Header: "File Nawme", accessor: "name" },
      { Header: "Action", accessor: "action", width: "10%" },
    ],

    rows: [],
  });
  const [generateFinished, setGenerateFinished] = useState(false);
  const handleFinishOpen = () => setGenerateFinished(true);
  const handleFinishClose = () => setGenerateFinished(false);
  const { getToken } = useKindeAuth();
  const systemType = "ongrid";
  const accessToken = localStorage.getItem("token");

  const timezoneOptions = [
    "Etc/GMT",
    "Etc/GMT+0",
    "Etc/GMT+1",
    "Etc/GMT+2",
    "Etc/GMT+3",
    "Etc/GMT+4",
    "Etc/GMT+5",
    "Etc/GMT+6",
    "Etc/GMT+7",
    "Etc/GMT+8",
    "Etc/GMT+9",
    "Etc/GMT+10",
    "Etc/GMT+11",
    "Etc/GMT+12",
    "Etc/GMT-0",
    "Etc/GMT-1",
    "Etc/GMT-2",
    "Etc/GMT-3",
    "Etc/GMT-4",
    "Etc/GMT-5",
    "Etc/GMT-6",
    "Etc/GMT-7",
    "Etc/GMT-8",
    "Etc/GMT-9",
    "Etc/GMT-10",
    "Etc/GMT-11",
    "Etc/GMT-12",
    "Etc/GMT-13",
    "Etc/GMT-14",
    "Etc/GMT0",
    "Etc/Greenwich",
    "Etc/UCT",
    "Etc/UTC",
    "Etc/Universal",
    "Etc/Zulu",
  ];
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Site Name", accessor: "name" },
      { Header: "Country", accessor: "country" },
      { Header: "Coordinate", accessor: "coordinate" },
      { Header: "Installation Date(New)", accessor: "installation_date_new" },
      { Header: "Installation Date(Used)", accessor: "installation_date_used" },
      { Header: "Panel Output", accessor: "panel_output" },
      { Header: "Timezone", accessor: "timezone" },
      { Header: "Power DC Rated", accessor: "power_dc_rated" },
      { Header: "Gamma PDC", accessor: "gamma_pdc" },
      { Header: "Tilt", accessor: "tilt" },
      { Header: "Azimuth", accessor: "azimuth" },
      { Header: "Mu", accessor: "mu" },
      { Header: "Action", accessor: "actions" },
    ],

    rows: [],
  });

  const handleCreateOpen = () => setCreateOpen(true);
  const handleCreateClose = () => setCreateOpen(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  async function createSite() {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}sites/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          name: siteName,
          country,
          coordinate,
          installation_date_new: installationDateNew,
          installation_date_used: installationDateUsed === "" ? null : installationDateUsed,
          panel_number: panelNumber,
          output_per_panel: outputPerPanel,
          panel_output: panelOutput,
          timezone,
          power_dc_rated: powerDcRated,
          gamma_pdc: gammaPdc,
          tilt,
          azimuth,
          mu,
        }),
      })
    ).json();
    setCurrentEditSiteId("");
    setSiteName("");
    setCountry("");
    setCoordinate("");
    setInstallationDateNew("");
    setInstallationDateUsed("");
    setPanelNumber(0);
    setOutputPerPanel(0);
    setPanelOutput(0);
    setTimezone("Etc/GMT+7");
    setPowerDcRated(1000);
    setGammaPdc(-0.005);
    setTilt(40);
    setAzimuth(180);
    setMu(0.8);
    fetchSites();
    console.log(response);
  }

  async function updateSite() {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}sites/${currentEditSiteId}/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          name: siteName,
          country,
          installation_date_new: installationDateNew,
          installation_date_used: installationDateUsed === "" ? null : installationDateUsed,
          panel_number: panelNumber,
          output_per_panel: outputPerPanel,
          panel_output: panelOutput,
          timezone,
          power_dc_rated: powerDcRated,
          gamma_pdc: gammaPdc,
          tilt,
          azimuth,
          mu,
        }),
      })
    ).json();
    fetchSites();
    console.log(response);
  }

  async function deleteFile(file) {
    const accessToken = await getToken();
    await fetch(`${serverBaseURL}files/${file}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        systemType,
        file,
      }),
    });
    fetchFiles();
  }

  async function fetchFiles() {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}files?systemType=${systemType}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();

    setRunningDataFiles({
      columns: [
        { Header: "File Nawme", accessor: "name" },
        { Header: "Action", accessor: "action", width: "10%" },
      ],

      rows: response.files.map((prop) => ({
        name: prop,
        action: (
          <div className="actions-right">
            <MDButton
              color="error"
              onClick={() => {
                deleteFile(prop);
              }}
            >
              <Icon fontSize="medium" color="white">
                close
              </Icon>
            </MDButton>
          </div>
        ),
      })),
    });
  }

  async function fetchSites() {
    const accessToken = await getToken();
    const response = await (
      await fetch(`${serverBaseURL}sites`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).json();
    console.log(response);
    setTableData({
      columns: [
        { Header: "Site Name", accessor: "name" },
        { Header: "Country", accessor: "country" },
        { Header: "Coordinate", accessor: "coordinate" },
        { Header: "Installation Date(New)", accessor: "installation_date_new" },
        { Header: "Installation Date(Used)", accessor: "installation_date_used" },
        { Header: "Panel Output", accessor: "panel_output" },
        { Header: "Timezone", accessor: "timezone" },
        { Header: "Power DC Rated", accessor: "power_dc_rated" },
        { Header: "Gamma PDC", accessor: "gamma_pdc" },
        { Header: "Tilt", accessor: "tilt" },
        { Header: "Azimuth", accessor: "azimuth" },
        { Header: "Mu", accessor: "mu" },
        { Header: "Action", accessor: "actions" },
      ],

      rows: response.map((prop, key) => ({
        id: key,
        name: prop.name,
        country: prop.country,
        coordinate: prop.coordinate,
        installation_date_new: prop.installation_date_new,
        installation_date_used: prop.installation_date_used,
        panel_output: prop.panel_output,
        timezone: prop.timezone,
        power_dc_rated: prop.power_dc_rated,
        gamma_pdc: prop.gamma_pdc,
        tilt: prop.tilt,
        azimuth: prop.azimuth,
        mu: prop.mu,
        actions: (
          <div className="actions-right">
            <MDButton
              onClick={() => {
                setCurrentEditSiteId(prop.id);
                setSiteName(prop.name);
                setCountry(prop.country);
                setCoordinate(prop.coordinate);
                setInstallationDateNew(prop.installation_date_new);
                setInstallationDateUsed(prop.installation_date_used);
                setPanelNumber(prop.panel_number);
                setOutputPerPanel(prop.output_per_panel);
                setPanelOutput(prop.panel_output);
                setTimezone(prop.timezone);
                setPowerDcRated(prop.power_dc_rated);
                setGammaPdc(prop.gamma_pdc);
                setTilt(prop.tilt);
                setAzimuth(prop.azimuth);
                setMu(prop.mu);
                handleEditOpen();
              }}
            >
              <Icon fontSize="medium" color="primary">
                edit
              </Icon>
            </MDButton>
          </div>
        ),
      })),
    });
  }

  useEffect(() => {
    fetchSites();
    fetchFiles();
  }, []);

  async function caculateRequest() {
    const accessToken = await getToken();
    handleFinishOpen();
    await (
      await fetch(`${serverBaseURL}caculate_result`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          task_type: systemType,
        }),
      })
    ).json();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Modal
          open={generateFinished}
          onClick={handleFinishClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox
            p={5}
            position="absolute"
            color="white"
            bgColor="white"
            transform="translate(-50%, -50%)"
          >
            <MDTypography variant="h5">
              Generate Task Issued. You can check status on other pages.
            </MDTypography>
            <br />
            <Stack spacing={5} direction="row">
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleFinishClose();
                }}
              >
                OK
              </MDButton>
            </Stack>
          </MDBox>
        </Modal>
        <Modal
          open={createOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox
            p={5}
            position="absolute"
            color="white"
            bgColor="white"
            transform="translate(-50%, -50%)"
          >
            <Card sx={{ position: "relative", minWidth: "400px", mt: -8, mx: 3, py: 2, px: 4 }}>
              <MDTypography variant="h5">
                Create A new Site
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </MDTypography>
              <FormField
                type="text"
                label="Name"
                defaultValue={siteName}
                onChange={(e) => setSiteName(e.target.value)}
              />
              <FormField
                type="text"
                label="Country"
                defaultValue={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <FormField
                type="text"
                label="Coordinate"
                defaultValue={coordinate}
                onChange={(e) => setCoordinate(e.target.value)}
              />
              <FormField
                type="text"
                label="Installation date(New) (in YYYY-MM-DD format)"
                defaultValue={installationDateNew}
                onChange={(e) => setInstallationDateNew(e.target.value)}
              />
              <FormField
                type="text"
                label="Installation date(Used) (in YYYY-MM-DD format)"
                defaultValue={installationDateUsed}
                onChange={(e) => setInstallationDateUsed(e.target.value)}
              />
              <FormField
                type="number"
                label="Panel Number"
                defaultValue={panelNumber}
                onChange={(e) => setPanelNumber(e.target.value)}
              />
              <FormField
                type="number"
                label="Output per Panel(kW)"
                defaultValue={outputPerPanel}
                onChange={(e) => setOutputPerPanel(e.target.value)}
              />
              <FormField
                type="number"
                label="Panel Output(kW)"
                defaultValue={panelOutput}
                onChange={(e) => setPanelOutput(e.target.value)}
              />
              <Autocomplete
                value={timezone}
                onChange={(e, v) => setTimezone(v)}
                inputValue={inputTimezone}
                onInputChange={(e, v) => setInputTimezone(v)}
                options={timezoneOptions}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="Timezone" />
                )}
              />
              <FormField
                type="number"
                label="Power DC rated"
                defaultValue={powerDcRated}
                onChange={(e) => setPowerDcRated(e.target.value)}
              />
              <FormField
                type="number"
                label="Gamma pdc"
                defaultValue={gammaPdc}
                onChange={(e) => setGammaPdc(e.target.value)}
              />
              <FormField
                type="number"
                label="Tilt"
                defaultValue={tilt}
                onChange={(e) => setTilt(e.target.value)}
              />
              <FormField
                type="number"
                label="Azimuth"
                defaultValue={azimuth}
                onChange={(e) => setAzimuth(e.target.value)}
              />
              <FormField
                type="number"
                label="Mu"
                defaultValue={mu}
                onChange={(e) => setMu(e.target.value)}
              />
            </Card>
            <Stack mt={3} ml={5} spacing={5} direction="row">
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  createSite();
                  handleCreateClose();
                }}
              >
                Save
              </MDButton>
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleCreateClose();
                  setCurrentEditSiteId("");
                  setSiteName("");
                  setCountry("");
                  setCoordinate("");
                  setInstallationDateNew("");
                  setInstallationDateUsed("");
                  setPanelNumber(0);
                  setOutputPerPanel(0);
                  setPanelOutput(0);
                  setTimezone("Etc/GMT+7");
                  setPowerDcRated(1000);
                  setGammaPdc(-0.005);
                  setTilt(40);
                  setAzimuth(180);
                  setMu(0.8);
                }}
              >
                Close
              </MDButton>
            </Stack>
          </MDBox>
        </Modal>
        <Modal
          open={editOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <MDBox
            p={5}
            position="absolute"
            color="white"
            bgColor="white"
            transform="translate(-50%, -50%)"
          >
            <Card sx={{ position: "relative", minWidth: "400px", mt: -8, mx: 3, py: 2, px: 4 }}>
              <MDTypography variant="h5">Create A new Site</MDTypography>
              <FormField
                type="text"
                label="Name"
                defaultValue={siteName}
                onChange={(e) => setSiteName(e.target.value)}
              />
              <FormField
                type="text"
                label="Country"
                defaultValue={country}
                onChange={(e) => setCountry(e.target.value)}
              />
              <FormField
                type="text"
                label="Installation date(New)"
                defaultValue={installationDateNew}
                onChange={(e) => setInstallationDateNew(e.target.value)}
              />
              <FormField
                type="text"
                label="Installation date(Used)"
                defaultValue={installationDateUsed}
                onChange={(e) => setInstallationDateUsed(e.target.value)}
              />
              <FormField
                type="number"
                label="Panel Number"
                defaultValue={panelNumber}
                onChange={(e) => setPanelNumber(e.target.value)}
              />
              <FormField
                type="number"
                label="Output per Panel(kW)"
                defaultValue={outputPerPanel}
                onChange={(e) => setOutputPerPanel(e.target.value)}
              />
              <FormField
                type="text"
                label="Panel Output(kW)"
                defaultValue={panelOutput}
                onChange={(e) => setPanelOutput(e.target.value)}
              />
              <Autocomplete
                value={timezone}
                onChange={(e, v) => setTimezone(v)}
                inputValue={inputTimezone}
                onInputChange={(e, v) => setInputTimezone(v)}
                options={timezoneOptions}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="Timezone" />
                )}
              />
              <FormField
                type="text"
                label="Power DC rated"
                defaultValue={powerDcRated}
                onChange={(e) => setPowerDcRated(e.target.value)}
              />
              <FormField
                type="text"
                label="Gamma pdc"
                defaultValue={gammaPdc}
                onChange={(e) => setGammaPdc(e.target.value)}
              />
              <FormField
                type="text"
                label="Tilt"
                defaultValue={tilt}
                onChange={(e) => setTilt(e.target.value)}
              />
              <FormField
                type="text"
                label="Azimuth"
                defaultValue={azimuth}
                onChange={(e) => setAzimuth(e.target.value)}
              />
              <FormField
                type="text"
                label="Mu"
                defaultValue={mu}
                onChange={(e) => setMu(e.target.value)}
              />
            </Card>
            <Stack mt={3} ml={5} spacing={5} direction="row">
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  updateSite();
                  handleEditClose();
                }}
              >
                Yes
              </MDButton>
              <MDButton
                p={5}
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleEditClose();
                }}
              >
                No
              </MDButton>
            </Stack>
          </MDBox>
        </Modal>
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={4} md={4} lg={4}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="dark" onClick={() => caculateRequest()}>
                Generate Result
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Site Management
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3} alignItems="right">
                <Grid item xs={5} md={5} lg={5}>
                  <MDBox mb={1}> </MDBox>
                </Grid>
                <Grid item xs={5} md={5} lg={5}>
                  <MDBox mb={1}> </MDBox>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                  <MDBox mb={1}>
                    <MDButton variant="gradient" color="dark" onClick={handleCreateOpen}>
                      Add New
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={tableData} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
        <Grid container alignItems="center" spacing={5}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <MDTypography variant="h5">Running Data</MDTypography>
              <MDBox mt={3}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Running Data
                  </MDTypography>
                </MDBox>
                {useMemo(
                  () => (
                    <MDDropzone
                      options={{
                        url: `${serverBaseURL}files/`,
                        XMLHttpRequest: true,
                        addRemoveLinks: false,
                        /*
                        headers: async () => {
                          const accessToken = await getToken();
                          return {
                            "Cache-Control": null,
                            "X-Requested-With": null,
                            Authorization: `Bearer ${accessToken}`,
                          };
                        },
                        */
                        headers: {
                          "Cache-Control": null,
                          "X-Requested-With": null,
                          Authorization: `Bearer ${accessToken}`,
                        },
                        params: (files, xhr, chunk) => {
                          if (chunk) {
                            return {
                              dzuuid: chunk.file.upload.uuid,
                              dzchunkindex: chunk.index,
                              dztotalfilesize: chunk.file.size,
                              dzchunksize: 2 * 1024 * 1024,
                              dztotalchunkcount: chunk.file.upload.totalChunkCount,
                              dzchunkbyteoffset: chunk.index * 2 * 1024 * 1024,
                            };
                          }

                          return {
                            systemType,
                          };
                        },
                        success() {
                          fetchFiles();
                        },
                        /*
                        async sending(_, xhr) {
                          const accessToken = await getToken();
                          xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
                        },
                        */
                      }}
                      autoProcessQueue={false}
                    />
                  ),
                  []
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5}>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="success"
                color="white"
                shadow="md"
                borderRadius="xl"
                ml={3}
                mt={-2}
              >
                <Icon fontSize="medium" color="inherit">
                  assignment
                </Icon>
              </MDBox>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Runing Data Files
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <DataTable table={runningDataFiles} canSearch />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <br />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default SiteManager;
